<template>
  <header class="rc-header">
    <div class="rc-header-container">
      <a href="/">
        <h2 class="logo" title="苏州威斯尼斯人8188cc控股集团有限公司"></h2>
      </a>
      <nav class="nav">
        <div v-for="item in menuList" :key="item.code" class="nav-item" @click="chooseNav(item)"
          :class="{ cur: curNav == item.route }">
          <span class="text-6">{{ item.name }}</span>
        </div>
      </nav>
    </div>
  </header>
</template>

<script setup>
import { computed } from 'vue'
import { useRouter, useRoute } from 'vue-router';

// 菜单
const menuList = [
  { name: '首页', route: '/' },
  { name: '公司概况', route: '/company' },
  { name: '解决方案', route: '/solution/zhinengwaibao' },
  { name: '威斯尼斯人8188cc', route: '/about' },
  // { name: '薪资查询', route: '/salary' },
  // { name: '增值业务', route: '/management' },
  { name: '招贤纳士', route: '/recruit' },
  { name: '联系威斯尼斯人8188cc', route: '/contact' },
]

const router = useRouter();
const route = useRoute();

const curNav = computed(() => {
  return route.path;
})
const chooseNav = (navItem) => {
  router.push(navItem.route)
}

</script>

<style lang="scss" scoped>
.rc-header {
  background-color: #fff;
  position: fixed;
  top: 0;
  z-index: 100;
  width: 100%;
}

.rc-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 74px;
}

.logo {
  width: 164px;
  height: 41px;
  background-image: url('../../assets/imgs/logo.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.nav {
  display: flex;
  justify-content: space-between;
}

.nav-item {
  cursor: pointer;

  &:hover,
  &.cur {
    color: #216CE0;
  }
}
</style>
<template>
  <div class="footer">
    <div class="footer-top center-content">
      <div class="footer-top-left">
        <h2 class="footer-logo"></h2>
        <div class="simple-info-container">
          <div class="single-info" v-for="(item, index) in simpleInfoList" :key="index">
            <Iconfont :icon="item.icon" :size="item.size"/>
            <el-divider direction="vertical" class="divider" />
            <p class="info-content text-3">{{ item.content }}</p>
          </div>
        </div>
        <div class="share-list-wrap">
          <div class="share-item" v-for="item in shareList" :key="item.icon" @mouseenter="shareItemMouseenter(item)" @mouseleave="shareItemMouseleave">
            <transition name="el-fade-in">
              <div v-show="item.url === shareState.url" class="code-box">
                <img :src="item.url" alt="">
              </div>
            </transition>
            <Iconfont color="#333" :icon="item.icon"/>
          </div>
        </div>
      </div>
      <nav class="footer-top-right">
        <div class="footer-nav" v-for="item in footerNav" :key="item.label">
          <h4 class="footer-nav-title text-1">{{ item.label }}</h4>
          <span  class="footer-nav-item text-2" v-for="it in item.menus" :key="it.label"
            @click="goPage(it)">
            {{ it.label }}
          </span>
        </div>
      </nav>
    </div>
    <div class="footer-bottom">
      <!-- <span>{{ bottomInfo.info1 }}</span> -->
      <span class="text-2">{{ bottomInfo.info2 }}</span>
    </div>
  </div>
</template>

<script setup>
import Iconfont from '@/components/iconfont';
import usePageStore from '@/store/pageStore';
import { reactive } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const pageStore = usePageStore();

const simpleInfoList = [
  { icon: 'icon-Vector-2', size: '14', content: '服务热线：0512-65924413' },
  { icon: 'icon-youxiang', size: '12', content: '服务邮箱：shiyunqian@szrc-hr.com' },
  { icon: 'icon-dizhi', size: '16', content: '总部地址：苏州市工业园区凤里街336号人力资源产业园1-B幢414-417室' },
]
const shareState = reactive({
  visible: false,
  ma: '',
})
const shareList = [
  { 
    code: 'wx',
    icon: 'icon-weixin',
    url: './imgs/ma/wx.png'
  },
  { 
    code: 'dy',
    icon: 'icon-Vector-1',
    url: './imgs/ma/douyin.png'
  },
  { 
    code: 'ks',
    icon: 'icon-Union',
    url: './imgs/ma/kuaishou.png'
  },
  // { icon: 'icon-Vector' },
  // { icon: 'icon-weibo' },
]

const shareItemMouseenter = (item) => {
  shareState.visible = true;
  shareState.url = item.url;
}
const shareItemMouseleave = () => {
  shareState.visible = false;
  shareState.url = '';
}

const footerNav = [
  {
    label: '关于威斯尼斯人8188cc',
    menus: [
      { label: '公司简介', route: '/company' },
      { label: '团队概况', route: '/company', anchor: 'teamInfo' },
      { label: '威斯尼斯人8188cc文化', route: '/company', anchor: 'culture' },
      { label: '发展历程', route: '/company', anchor: 'development' },
      { label: '团建活动', route: '/company', anchor: 'activity' },
    ]
  },
  {
    label: '解决方案',
    menus: [
      { label: '智能外包', route: '/solution/zhinengwaibao' },
      { label: '项目外包', route: '/solution/xiangmuwaibao' },
      { label: '生产外包', route: '/solution/shengchanwaibao' },
      { label: '岗位外包', route: '/solution/gangweiwaibao' },
      { label: '人力资源外包', route: '/solution/renliziyuanwaibao' },

    ]
  },
  {
    label: '威斯尼斯人8188cc',
    menus: [
      { label: '威斯尼斯人8188cc', route: '/about' },
    ]
  },
  {
    label: '招贤纳士',
    menus: [
      { label: '招贤纳士', route: '/recruit' },
    ]
  },
]

const bottomInfo = {
  info2: '备案号：苏ICP备2022037950号-1'
}

const goPage = (item) => {
  pageStore.setCompanyAnchor(item.anchor || '');
  router.push(item.route);
}
</script>

<style lang="scss" scoped>
.footer {
  display: flex;
  flex-direction: column;
  color: #cdcdcd;
  background-color: #333333;
  .footer-top {
    display: flex;
    justify-content: space-between;
    padding-top: 60px;
  }
  .footer-top-left {
    display: flex;
    flex-direction: column;
  }
  .footer-logo {
    width: 190px;
    height: 47px;
    flex: 0 0 47px;
    background-image: url('../../assets/imgs/footer-logo.svg');
    background-repeat: no-repeat;
    background-size: contain;
  }
  .simple-info-container {
    margin-top: 28px;
    display: flex;
    flex-direction: column;
  }
  .single-info {
    display: flex;
    align-items: center;
    & + .single-info {
      margin-top: 16px;
    }
    .divider {
      border-left: 1px solid #CDCDCD;
    }
  }
  .info-content {
    width: 292px;
    line-height: 18px;
    text-wrap: wrap;
  }
  .share-list-wrap {
    margin-top: 32px;
    display: flex;
  }
  .share-item {
    cursor: pointer;
    position: relative;
    width: 28px;
    height: 28px;
    background-color: #d9d9d9;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    & + .share-item {
      margin-left: 24px;
    }
    .code-box {
      position: absolute;
      bottom: 36px;
      left: 0;
      &::after {
        content: '';
        width: 0;
        height: 0;
        border: 8px solid #fff;
        border-left: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-right: 6px solid transparent;
        position: absolute;
        bottom: -9px;
        left: 8px;
      }
      img {
        width: 108px;
        height: 108px;
        background-color: #fff;
      }
    }
  }
  .footer-top-right {
    margin-left: 125px;
    flex: 1;
    display: flex;
    justify-content: space-between;
  }
  .footer-nav {
    display: flex;
    flex-direction: column;
  }
  .footer-nav-title {
    color: #fff;
  }

  .footer-nav-item {
    cursor: pointer;
    margin-top: 24px;
    line-height: 16px;
    &:hover {
      color: #216CE0;
      text-decoration: underline;
    }
    & + .footer-nav-item {
      margin-top: 16px;
    }
  }

  .footer-bottom {
    margin-top: 12px;
    border-top: 1px solid #4E4E4E;
    padding: 32px 0 38px;
    text-align: center;
  }
}

</style>